<script setup>
  import { onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import axios from '../../services/ApiService';

  const DEBOUNCE_TIME = 750;
  const MIN_TEXT_LENGTH = 3;

  const { t } = useI18n();

  let debounceRunning = false;
  let timer = null;

  const props = defineProps({
    value: String
  });

  const suggestions = ref([]);
  const selectedIndex = ref(-1);

  const debounce = function(text) {
    if (debounceRunning || text.length < MIN_TEXT_LENGTH) return Promise.resolve(false);

    debounceRunning = true;
    return new Promise((resolve) => {
      if (timer === null) {
        timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          debounceRunning = false;
          resolve(true);
        }, DEBOUNCE_TIME);
      }
    });
  }

  const search = async (event) => {
    if (event.inputType === 'insertFromPaste') {
      searchTrigger(event.target.value);
    } else if (event.inputType === 'insertText' || event.inputType === 'deleteContentBackward') {
      debounce(event.target.value).then(async (valid) => {
        if (!valid) {
          suggestions.value = [];
          selectedIndex.value = -1;
          return;
        }

        searchTrigger(event.target.value);
      });
    }
  }

  const searchTrigger = async (text) => {
    try {
      const response = await axios.get(`/api/suggests.json?q=${text}`);
      suggestions.value = response.data.hits;
    } catch (error) {
      console.error('Error fetching data:', error);
      suggestions.value = [];
    }
    selectedIndex.value = -1;
  }

  const clearSuggestions = () => {
    setTimeout(() => {
      suggestions.value = [];
      selectedIndex.value = -1;
    }, 500);
  }

  const reloadSuggestions = async (event) => {
    if (event.target.value.length >= MIN_TEXT_LENGTH) {
      searchTrigger(event.target.value);
    }
  }

  const highlightSuggestion = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      if (suggestions.value.length === 0) return;

      if (event.key === 'ArrowDown') {
        selectedIndex.value = selectedIndex.value + 1;
        if (selectedIndex.value >= suggestions.value.length) {
          selectedIndex.value = -1;
        }
      } else if (event.key === 'ArrowUp') {
        selectedIndex.value = selectedIndex.value - 1;
        if (selectedIndex.value < -1) {
          selectedIndex.value = suggestions.value.length - 1;
        }
      }
    } else if (event.key === 'Enter') {
      if (selectedIndex.value >= 0) {
        event.preventDefault();

        const suggestion = suggestions.value[selectedIndex.value];
        window.location.href = suggestion.url;
      }
    }
  }

  const mouseHighlight = (index) => {
    selectedIndex.value = index;
  }

  const mouseUnHighlight = () => {
    selectedIndex.value = -1;
  }
</script>

<template>
  <form autocomplete="off" action="/search" accept-charset="UTF-8" method="get" :class="{ expanded: suggestions.length > 0 }">
    <label for="search" class="nav-search-text__label">
      <i class="material-icons nav-search__icon" aria-hidden="true">search</i>
      <span class="sr-only">{{ t('search.label') }}</span>
    </label>
    <input type="text" name="query" id="search" class="nav-search__input" :placeholder="t('search.label') + '...'" v-on:input="search" v-on:blur="clearSuggestions" v-on:focus="reloadSuggestions" autocomplete="off" v-on:keydown="highlightSuggestion">
    <a class="nav-search__advanced" data-post-hog="home-advanced" href="/advanced_search">{{ t('search.advanced') }}</a>
  </form>
  <div v-if="suggestions.length > 0" class="search-suggestions">
    <a v-for="(suggestion, index) in suggestions" :class="{ 'search-suggestion': true, 'highlighted': index == selectedIndex }" :href="suggestion.url" v-on:mouseenter="mouseHighlight(index)" v-on:mouseleave="mouseUnHighlight">
      <span :class="`suggestion-pill ${suggestion.object_type}`">{{ t(`search.object_types.${suggestion.object_type}`) }}</span>
      <span class="suggestion-label">{{ suggestion.text }}</span>
      <i class="material-icons link-icon" aria-hidden="true">arrow_forward</i>
    </a>
  </div>
</template>

<style  scoped lang="scss">
  form {
    display: flex;
    align-items: center;
    max-width: 50rem;
    border: 2px solid var(--light-gray-2);
    border-radius: 1.4rem;
    padding: 3px 0;
    margin: 2px 0 12px 0;

    &:hover,
    &:focus-within {
      border-color: var(--primary);
    }

    &.expanded {
      border-radius: 1.4rem 1.4rem 0 0;
      border-bottom: none;
      border-color: var(--primary);
    }
  }

  .search-suggestions {
    position: absolute;
    top: 38px;
    background-color: white;
    width: calc(100% - 4px);
    border: 2px solid var(--primary);
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    padding-bottom: 0.6rem;
    z-index: 1;
  }

  .search-suggestion {
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--dark-gray-2);

    &.highlighted {
      background-color: lightgray;

      .link-icon {
        color: gray;
      }
    }
  }

  .suggestion-label {
    flex-grow: 2;
  }

  .link-icon {
    color: lightgray;
  }

  .suggestion-pill {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    margin-right: 0.5rem;

    &.collection {
      background-color: #ff595e;
      color: white;
    }

    &.imprint {
      background-color: #ffca3a;
      color: black;
    }

    &.list {
      background-color: #8ac926;
      color: white;
    }

    &.contributor {
      background-color: #1982c4;
      color: white;
    }
  }
</style>

<i18n src="../../locales/en.json" global></i18n>
<i18n src="../../locales/fr.json" global></i18n>
<i18n src="../../locales/es.json" global></i18n>
<i18n src="../../locales/it.json" global></i18n>
<i18n src="../../locales/pt.json" global></i18n>
