import { defineStore } from "pinia";
import { cleanUpFields } from "../pages/helpers/CatalogPublicationFiltersHelper";

export const usePublicationsStore = defineStore("PublicationsStore", {
  state: () => ({
    publications: {
      data: [],
      included: [],
      meta: {
        pagination: {
          current_page: 1,
          total_pages: 1,
          total_count: 0,
          per_page: 25
        },
        aggregations: null
      }
    },
    loading: false,
    queryParams: '',
    selectedItems: []
  }),
  actions: {
    async fetchPublications(params = {}, from_filters = false) {
      let isbnQuery = '';
      if(params.isbns) {
        isbnQuery = params.isbns.map(isbn => `isbns[]=${isbn}`).join('&');
        delete params.isbns;
      }
      if (from_filters) {
        params = cleanUpFields(params);
        if(!params.territory_state) params.territory_state = 'default';
      }
      this.loading = true;
      this.queryParams = new URLSearchParams(params).toString();

      if(from_filters) {
        params = cleanUpFields(params, 'All');
      }
      let query = new URLSearchParams(params).toString();
      if(isbnQuery) {
        query = query ? `${query}&${isbnQuery}` : isbnQuery;
      }

      try {
        if(query) {
          window.history.replaceState({}, '', `?${query}`);
        } else {
          window.history.replaceState({}, '', '');
        }

        const response = await this.$axios.get(`/api/v1/catalog/store_items.json?${query}`);
        this.publications = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      this.loading = false;
    },

    async requestExport(objParams) {
      const { urlParams, payload } = objParams;
      let query = new URLSearchParams(urlParams).toString();
      try {
        const response = await this.$axios.post(`/api/v1/catalog/store_items?${query}`, payload);
        return response;
      } catch (error) {
        return error.response;
      }
    }
  }
});
