<script setup>
  import { onMounted, ref, toRaw, computed, watch, defineExpose, defineEmits } from 'vue'
  import { useI18n } from 'vue-i18n';
  import VueMultiselect from 'vue-multiselect';
  import { useCatalogPublicationAvailabilitiesStore } from '../stores/CatalogPublicationAvailabilitiesStore';
  import { useCatalogPublicationGeneralStore } from '../stores/CatalogPublicationGeneralStore';
  import { useCatalogPublicationInfluencesStore } from '../stores/CatalogPublicationInfluencesStore';
  import { useCatalogPublicationLibraryStore } from '../stores/CatalogPublicationLibraryStore.js';
  import { usePublicationsStore } from '../stores/PublicationStore.js';
  import { usePublicationFiltersStore } from '../stores/PublicationFiltersStore.js';
  import { useCatalogPublicationSettingStore } from '../stores/CatalogPublicationSettingStore.js';
  import { storeToRefs } from 'pinia';
  import { useRouter, useRoute } from 'vue-router';
  import {
    mapInfluences,
    obtainMarkets,
    mapAudiences,
    mapProviders,
    setSelectorsMargin,
    obtainValues,
    preventAllRemoval,
    findLibrary,
    setDefaultValues,
    selectAllOrSeveral,
    translateAll,
    cleanUpFields,
    multipleSelectFields,
    openFields,
    mapSettings,
    dynamicFields
  } from './helpers/CatalogPublicationFiltersHelper';
  import moment from 'moment'
  import '../stylesheets/catalog-publication.scss'
  import SorterMenu from './catalog_publication_fields/SorterMenu.vue';
  import Sorter from './catalog_publication_fields/Sorter.vue';
  import { useTableColumnStore } from '../stores/TableColumnStore.js';

  // Import field components
  import Date from './catalog_publication_fields/Date.vue';
  import DynamicSearch from './catalog_publication_fields/DynamicSearch.vue';

  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const publicationDate = ref(null);
  const offerCreationDate = ref(null);
  const showChangePositionElement = ref(false);
  const params = new URLSearchParams(window.location.search);
  const availabilitiesStore = useCatalogPublicationAvailabilitiesStore();
  const publicationsStore = usePublicationsStore();
  const generalStore = useCatalogPublicationGeneralStore();
  const influencesStore = useCatalogPublicationInfluencesStore();
  const libraryStore = useCatalogPublicationLibraryStore();
  const publicationFiltersStore = usePublicationFiltersStore();
  const catalogPublicationSettingStore = useCatalogPublicationSettingStore();
  const { isbns, inject_isbns} = storeToRefs(publicationFiltersStore);
  const emit = defineEmits(['rebuildSelections']);
  const { resetInjection } = publicationFiltersStore;
  const selectedSetting = ref(null);
  const {
    getFiltersCanBeHidden,
    activeFilters,
    inactiveFilters,
    orderFilters,
    setOrder,
    reset,
    getOriginals
  } = useTableColumnStore();
  const categorySelectorRef = ref(null);
  const librarySelectorRef = ref(null);
  const selectedLibraryName = ref(null);

  const MULTISELECT_ARRAYS = [
    { name: 'providers', arr: [t('components.catalog_publication.filters.all')] },
    { name: 'audiences', arr: [{ id: null, name: t('components.catalog_publication.filters.all') }] },
    { name: 'catalog_types', arr: [{ value: t('components.catalog_publication.filters.all'), name: t('components.catalog_publication.filters.all') }] },
    { name: 'book_formats', arr: [{  "id": 1, "name": t('components.catalog_publication.filters.all'), "key": null }] }
  ]
  const SEARCH_FIELDS_AND_SELECTORS =[
    { field: 'category', selector: 'categorySelector' },
    { field: 'author_id', selector: 'authorSelector' },
    { field: 'imprint_id', selector: 'imprintSelector' },
    { field: 'library_id', selector: 'librarySelector' },
    { field: 'lang', selector: 'languageSelector' }
  ]
  const DATE_FIELDS = [
    'offer_creation_date_start',
    'offer_creation_date_end',
    'publication_date_start',
    'publication_date_end'
  ];

  const enableFields = () => {

    const shownFieldsDiv = document.getElementById('shown_fields');
    const hiddenFieldsDiv = document.getElementById('not_shown_fields');

    const orderedActiveFilters = orderFilters.filter((field) => activeFilters.includes(field));
    for (let field of orderedActiveFilters) {
      const fieldDiv = document.getElementById(`field-${field}`);
      if (fieldDiv) shownFieldsDiv.appendChild(fieldDiv);
    }

    for (let field of inactiveFilters) {
      const fieldDiv = document.getElementById(`field-${field}`);
      if (fieldDiv) hiddenFieldsDiv.appendChild(fieldDiv);
    }
  }

  const props = defineProps({
    lang: {
      type: String,
      required: true,
      default: 'en'
    },
    urlQuery: {
      type: String,
      required: false,
      default: ''
    }
  });
  const hasDebug = ref(false);
  const marketsMultiSelectRef = ref(null);
  const connectorsMultiSelectRef = ref(null);
  const loaded = ref(false);
  const catalogued = ref([
    { value: null, name: t('components.catalog_publication.filters.default') },
    { value: true, name: t('components.catalog_publication.filters.option_yes') },
    { value: false, name: t('components.catalog_publication.filters.option_no') }
  ]);
  const allTrueFalse = ref([
    { value: 'All', name: t('components.catalog_publication.filters.all') },
    { value: 'true', name: t('components.catalog_publication.filters.option_yes') },
    { value: 'false', name: t('components.catalog_publication.filters.option_no') }
  ]);
  const catalog_types = ref([
    { value: 'All', name: t('components.catalog_publication.filters.all') },
    { value: 'traditional', name: t('components.catalog_publication.filters.catalog_type_traditional') },
    { value: 'self_published', name: t('components.catalog_publication.filters.catalog_type_self_published') },
    { value: 'public_domain', name: t('components.catalog_publication.filters.catalog_type_public_domain') }
  ]);
  const availabilities = ref([
    { key: 'All', name: t('components.catalog_publication.filters.all'), main: true },
    { key: 'available', name: t('components.catalog_publication.filters.available'), main: true },
    { key: 'unavailable', name: t('components.catalog_publication.filters.unavailable'), main: true }
  ]);
  let showLibraries = ref(true);

  // These are the same names that we have in the backend
  const initialFilters = {
    debug: false,
    library_id: null,
    network_id: null,
    territory_state: 'All',
    connector: [],
    lang: null,
    availability: null,
    book_formats: [],
    age: [],
    catalogued: null,
    catalog_types: [],
    from_quebec: 'All',
    cover: 'All',
    influence: null,
    title: null,
    isbn: null,
    author_id: null,
    imprint_id: null,
    collection_id: null,
    offer_creation_date_start: null,
    offer_creation_date_end: null,
    publication_date_start: null,
    publication_date_end: null,
    isbns: [],
    cat: null
  }

  const initialSelecFields = {
    markets: [],
    connectors: [],
    book_formats: [],
    audiences: [],
    providers: [],
    catalog_types: []
  }

  // SelectedData
  const selected = ref({...initialFilters});

  // The info for multiselect fields is kept separately
  // since it is not part of the selected data (includes unnecessary data)
  const multiselect = ref({...initialSelecFields});

  // Loaded data
  let loadedOptions = ref({});

  const providers = computed(() => loadedOptions.value.providers);
  const book_formats = computed(() => loadedOptions.value.book_formats);
  const markets = computed (() => { return loadedOptions.value.markets; });
  const connectors = computed (() => { return loadedOptions.value.connectors; });
  const audiences = computed(() => loadedOptions.value.audiences);

  const rebuildSelections = async() => {
    let settingId;
    if (params.has('setting_id')) {
      settingId = parseInt(params.get('setting_id'));
    } else {
      return;
    }
    const settingsValues = catalogPublicationSettingStore.data;
    const values = mapSettings(toRaw(settingsValues.data));

    selectedSetting.value = values.find(setting => setting.id === settingId);
    if (!selectedSetting.value) return;
    loaded.value = settingId;

    await setOrder(toRaw(selectedSetting.value.filterPositions), 'filters');
    setTimeout(() => {
      enableFields();
      setSelectorsMargin();
    }, 100);

    // Fields not included in activeFilters
    if (params.has('offer_creation_date_start')) {
      activeFilters.push('offer_creation_date_start');
    };
    if (params.has('offer_creation_date_end')) {
      activeFilters.push('offer_creation_date_end');
    };
    if (params.has('publication_date_start')) {
      activeFilters.push('publication_date_start');
    };
    if (params.has('publication_date_end')) {
      activeFilters.push('publication_date_end');
    };
    if (params.has('library_id')) {
      activeFilters.push('library_id');
    };

    for (let field of activeFilters) {

      const value = params.has(field) ? params.get(field) : null;
      if (value == null)  continue;

      // Multiples
      if (multipleSelectFields.hasOwnProperty(field)) {
        const allValues = value.split(',');
        for (let value in allValues) {
          const selected = allValues[value];
          const lowerValue = selected.toLowerCase();
          const upperValue = selected.toUpperCase();
          const index = toRaw(book_formats.value).map(e => e.name.toLowerCase()).indexOf(lowerValue);
          const item = {id: index + 1, name: upperValue, key: lowerValue};
          multiselect.value.book_formats.push(item);
        }
      }

      // String fields
      if (openFields.includes(field)) {
        if (field == 'title_subtitle') field = 'title';
        selected.value[field] = value;
      }

      // Dynamic fields
      if (dynamicFields.includes(field)) {
        if (field == 'cat' || field == 'categories') field = 'category';
        selected.value[field] = value;
        const selector = SEARCH_FIELDS_AND_SELECTORS.find(e => e.field === field);
        if (field == 'category') categorySelectorRef.value.setSelected(
          selector.selector,
          value,
          null
        );
        if (field == 'library_id') {
          await optionClick(null, 'librarySelector', parseInt(value));
          librarySelectorRef.value.setSelected(
            selector.selector,
            value,
            selectedLibraryName.value
          );
        }
      }

      // Date fields
      if (DATE_FIELDS.includes(field)) {
        if (field == 'offer_creation_date_start') {
          selected.value.offer_creation_date_start = value;
          offerCreationDate.value.setStartDate(value);
        }
        if (field == 'offer_creation_date_end') {
          selected.value.offer_creation_date_end = value;
          offerCreationDate.value.setEndDate(value);
        }
        if (field == 'publication_date_start') {
          selected.value.publication_date_start = value;
          publicationDate.value.setStartDate(value);
        }
        if (field == 'publication_date_end') {
          selected.value.publication_date_end = value;
          publicationDate.value.setEndDate(value);
        }
      }
    }
  }
  defineExpose({ rebuildSelections });

  const optionClick = async (e, selectorId, option) => {
    const optionSelectorId = selectorId ? selectorId : e.target.parentElement.previousSibling.id;
    const selectedOption = option ? option : e.target.dataset.selected;
    if (optionSelectorId === 'categorySelector') selected.value.category = selectedOption;
    if (optionSelectorId === 'librarySelector') {
      await findLibrary(selectedOption, libraryStore, toRaw, selected, multiselect, t, loadedOptions, selectedLibraryName);
      selected.value.library_id = selectedOption;
    }
    if (optionSelectorId === 'languageSelector') selected.value.lang = selectedOption;
    if (optionSelectorId === 'authorSelector') selected.value.author_id = selectedOption;
    if (optionSelectorId === 'imprintSelector')  selected.value.imprint_id = selectedOption
    if (optionSelectorId === 'collectionsSelector') selected.value.collection_id = selectedOption;
  }

  onMounted( async () => {
    if (params.has('debug') && params.get('debug') === 'true') hasDebug.value = true;
    Promise.all([
      generalStore.fetchCatalogPublicationGeneral(),
      influencesStore.fetchCatalogPublicationInfluences(),
      availabilitiesStore.fetchCatalogPublicationAvailabilities()
    ]).then(() => {
      loadedOptions.value = { ...toRaw(generalStore.data.data).attributes, ...toRaw(availabilitiesStore.data) };
      loadedOptions.value.providers = mapProviders(toRaw(loadedOptions.value.providers));
      loadedOptions.value.influences = mapInfluences(toRaw(influencesStore.data.data));
      loadedOptions.value.audiences = mapAudiences(toRaw(loadedOptions.value.audiences));
      loadedOptions.value.networks.unshift({ id: null, name: t('components.catalog_publication.availabilities.none') });
      loadedOptions.value.territories.unshift({ id: 'All', name: t('components.catalog_publication.availabilities.default'), territory: null});
      loadedOptions.value.providers.unshift('All');
      loadedOptions.value.audiences.unshift({ id: null, name: t('components.catalog_publication.filters.all') });
      multiselect.value.catalog_types = [{ value: 'All', name: t('components.catalog_publication.filters.all') }];

      selected.from_quebec = 'All';
      selected.cover = 'All';

      setDefaultValues(multiselect, selected, t);
      loaded.value = true;

      setTimeout(() => {

        if (params.has('set_values') && params.get('set_values') === 'true') {
          resetFilters(false);
          reset('filters');

          // Reset fields, before setting again
          const hiddenFieldsDiv = document.getElementById('not_shown_fields');
          const originals = getOriginals('filters');
          for (let field of originals) {
            const fieldDiv = document.getElementById(`field-${field}`);
            if (fieldDiv) hiddenFieldsDiv.appendChild(fieldDiv);
          }

          rebuildSelections();
        } else {
          enableFields();
          setSelectorsMargin();
        }
      }, 100);
    })
    .catch(error => console.log(`Error at obtaining filters ${error}`));

  });

  const resetFilters = (reload = false) => {
    for (let field of SEARCH_FIELDS_AND_SELECTORS) {
      selected.value[field.field] = null;
      document.getElementById(field.selector).value = '';
    }

    publicationDate.value.clearDates();
    offerCreationDate.value.clearDates();

    multiselect.value = { markets: [], connectors: [], book_formats: [], audiences: [], providers: [], catalog_types: []};
    selected.value = { ...initialFilters };
    setDefaultValues(multiselect, selected, t);
    if (reload) publicationsStore.fetchPublications(selected.value, true);
  }

  const disableTerritoriesOrLibraries = (type) => {
    const territories = document.getElementById('territories_selector');
    const libraries = document.getElementById('libraries_selector');

    if (type === 'territories') {
      territories.classList.add('actions_hidden');
      libraries.classList.remove('actions_hidden');
      libraries.classList.add('actions_visible');

      selected.value.network_id = null;
      selected.value.territory_state = 'All';
      selected.value.markets = [];
      selected.value.connectors = [];
      multiselect.value = { ...multiselect.value, markets: [], connectors: [] };
      showLibraries.value = true;
    } else if (type === 'libraries') {
      libraries.classList.add('actions_hidden');
      territories.classList.remove('actions_hidden');
      territories.classList.add('actions_visible');

      selected.value.library_id = null;
      document.getElementById('librarySelector').value = '';
      showLibraries.value = false;
    }
  }

  const clearField = (id, input_id, loadedOption, reload = false) => {
    selected.value[id] = null;
    document.getElementById(input_id).value = '';
    if (reload) publicationsStore.fetchPublications(selected.value, true);
    if (loadedOption) loadedOptions.value[loadedOption] = [];
  }

  const gatherValues = () => {
    selected.value.connector = multiselect.value.connectors ? obtainValues(multiselect.value.connectors, 'name').join(',') : [];
    selected.value.markets = multiselect.value.markets ? obtainMarkets(multiselect.value.markets).join(',') : [];
    selected.value.book_formats = multiselect.value.book_formats ? obtainValues(multiselect.value.book_formats, 'key').join(',') : [];
    selected.value.catalog_types = multiselect.value.catalog_types ? obtainValues(multiselect.value.catalog_types, 'value').join(',') : [];
    selected.value.age = multiselect.value.audiences ? obtainValues(multiselect.value.audiences, 'id').join(',') : [];
    selected.value.provider = translateAll(multiselect.value.providers).join(',');
    return selected.value;
  }

  const applyFilters = async (e) => {
    const values = gatherValues();
    const params = new URLSearchParams(window.location.search);
    if (params.has('debug')) values.debug = true;
    else values.debug = false;
    await publicationsStore.fetchPublications(values, true);
  }

  const settingsRedirect = () => {
    const values = gatherValues();
    const params = cleanUpFields(values);
    const query = new URLSearchParams(params).toString();
    router.push(`/frontend/catalog/save_settings?${query}`);
  }

  const dateChange = (event) => {
    const { dataset, id, value } = event.target;
    const type = dataset.type;
    if (id === `${type}_date_start`) {
      selected.value[`${type}_date_start`] = value;
    } else if (id === `${type}_date_end`) {
      selected.value[`${type}_date_end`] = value;
    }

    const startDate = moment(selected.value[`${type}_date_start`], 'YYYY-MM-DD');
    const endDate = moment(selected.value[`${type}_date_end`], 'YYYY-MM-DD');
    const diff = endDate.diff(startDate, 'days');
    if (diff < 0) selected.value[`${type}_date_start`] = selected.value[`${type}_date_end`];
  }

  const showChangePositionAction = (reload) => {
    resetFilters(reload);
    enableFields();
    setSelectorsMargin();
  };

  const togglePositionMenu = () => {
    showChangePositionElement.value = true
  };

  watch(inject_isbns, async (newValue) => {
    if(newValue) {
      selected.value.isbns = isbns.value;
      await applyFilters();
      resetInjection();
    }
  });

</script>

<template>
  <span v-if="hasDebug">
    {{ selected }}
    <hr>
    {{ multiselect }}
    <hr>
  </span>
  <!-- Availabilities -->
  <h3>{{t('components.catalog_publication.availabilities.title')}}</h3>
  <div v-if="loaded">
    <div class="row">
      <div class="col-3" id="libraries_selector" @click="disableTerritoriesOrLibraries('territories', selected, multiselect)">
        <DynamicSearch
          :title="'libraries'"
          :id="'librarySelector'"
          :selectedId="'library_id'"
          :fetcher="'fetchCatalogPublicationLibraries'"
          :loadedOptionsProperty="'libraries'"
          :mapperAndLang="null"
          :lang="props.lang"
          @optionClick="optionClick"
          @clearField="clearField"
          ref="librarySelectorRef"
        />
      </div>
      <div class="col-9" id="territories_selector" @click="disableTerritoriesOrLibraries('libraries', selected, multiselect)">
        <div class="row">
          <div class="col">
            <label for="exampleFormControlInput1" class="form-label">
              {{t('components.catalog_publication.availabilities.networks')}}
            </label>
            <select class="form-select" v-model="selected.network_id">
              <option v-for="network in loadedOptions.networks" :value="network.id">
                {{ network.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <label for="exampleFormControlInput1" class="form-label">
              {{t('components.catalog_publication.availabilities.territories')}}
            </label>
            <select class="form-select" v-model="selected.territory_state" id="territory_state">
              <option v-for="territory in loadedOptions.territories" :value="`${territory.id}${territory.territory !== null ? `_${territory.territory.toLowerCase()}` : ''}`">
                {{ territory.territory !== null ? ` &nbsp; &nbsp; ${territory.name}` : territory.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <label for="exampleFormControlInput1" class="form-label">
              {{t('components.catalog_publication.availabilities.markets')}}
            </label>
            <VueMultiselect
              id="marketsMultiSelect"
              class="multiselect mtop"
              v-model="multiselect.markets"
              :placeholder="t('components.catalog_publication.filters.pick_several_options')"
              label="name"
              :options="markets"
              :multiple="true"
              :taggable="true"
              track-by="id"
              ref="marketsMultiSelectRef"
              @open="disableTerritoriesOrLibraries('libraries', selected, multiselect)"
              @select="((e) => selectAllOrSeveral(e, 'markets', multiselect))"
              :hideSelected="true">
            </VueMultiselect>
          </div>
          <div class="col">
            <label for="exampleFormControlInput1" class="form-label">
              {{t('components.catalog_publication.availabilities.connectors')}}
            </label>
            <VueMultiselect
              id="connectorsMultiSelect"
              class="multiselect mtop"
              v-model="multiselect.connectors"
              :placeholder="t('components.catalog_publication.filters.pick_several_options')"
              label="name"
              track-by="id"
              :options="connectors"
              :multiple="true"
              :taggable="true"
              ref="connectorsMultiSelectRef"
              @open="disableTerritoriesOrLibraries('libraries', selected, multiselect)"
              :hideSelected="true">
            </VueMultiselect>
          </div>
        </div>

      </div>
    </div>
  </div>

  <hr class="hr" />

  <!-- Filters -->
  <h3>{{t('components.catalog_publication.filters.title')}}</h3>
  <div v-if="loaded">

    <div class="row" id="shown_fields">
    </div>

    <div class="row" id="not_shown_fields" style="display: none;">
      <div
        class="col col-md-6 my-2"
        id="field-publication_date"
      >
        <Date
          @clearField="clearField"
          @dateChange="dateChange"
          :type="'publication'"
          ref="publicationDate"
        />
      </div>

      <div
        class="col col-md-6 my-2"
        id="field-catalog_date"
      >
        <Date
          @clearField="clearField"
          @dateChange="dateChange"
          :type="'offer_creation'"
          ref="offerCreationDate"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-provider"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.provider')}}</label>
        <VueMultiselect
          id="formatMultiSelect"
          class="multiselect mtop"
          v-model="multiselect.providers"
          :placeholder="t('components.catalog_publication.filters.pick_several_options')"
          :options="providers"
          :multiple="true"
          :taggable="true"
          @select="((e) => selectAllOrSeveral(e, 'providers', null, multiselect))"
          @remove="((e) => preventAllRemoval(e, multiselect, 'providers', MULTISELECT_ARRAYS))"
          :hideSelected="true">
        </VueMultiselect>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-language"
      >
        <DynamicSearch
          :title="'language'"
          :id="'languageSelector'"
          :selectedId="'lang'"
          :fetcher="'fetchCatalogPublicationLanguages'"
          :loadedOptionsProperty="'languages'"
          :mapperAndLang="{ mapper: 'mapLanguages', lang: props.lang }"
          @optionClick="optionClick"
          @clearField="clearField"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-availability"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.availability')}}</label>
        <select class="form-select" v-model="selected.availability" @input="inputChange">
          <option v-for="(availability) in availabilities" :value="availability.key">
            {{ availability.main ? availability.name : ` &nbsp; &nbsp; ${availability.name}` }}
          </option>
        </select>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-book_format"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.book_format')}}</label>
        <VueMultiselect
          id="formatMultiSelect"
          class="multiselect mtop"
          v-model="multiselect.book_formats"
          :placeholder="t('components.catalog_publication.filters.pick_several_options')"
          label="name"
          track-by="id"
          :options="book_formats"
          :multiple="true"
          :taggable="true"
          @select="((e) => selectAllOrSeveral(e, 'book_formats', multiselect))"
          @remove="((e) => preventAllRemoval(e.name, multiselect, 'book_formats', MULTISELECT_ARRAYS))"
          :hideSelected="true">
        </VueMultiselect>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-catalogued"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.catalogued')}}</label>
        <select class="form-select" v-model="selected.catalogued" @input="inputChange">
          <option v-for="catalog in catalogued" :value="catalog.value">
            {{ catalog.name }}
          </option>
        </select>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-category"
      >
        <DynamicSearch
          :title="'category'"
          :id="'categorySelector'"
          :selectedId="'category'"
          :fetcher="'categorySearch'"
          :loadedOptionsProperty="'categories'"
          :mapperAndLang="null"
          :lang="props.lang"
          @optionClick="optionClick"
          @clearField="clearField"
          ref="categorySelectorRef"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-influence"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.influence')}}</label>
        <select class="form-select" id="datalistInfluences" v-model="selected.influence" @input="inputChange">
          <option value="All" selected>All</option>
          <option v-for="influence in loadedOptions.influences" :value="influence.code">
            {{ influence.parent_id === null ? `${influence.name.toUpperCase()}` : ` &nbsp; ${influence.name}` }}
          </option>
        </select>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-audience"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.audience')}}</label>
        <VueMultiselect
          id="audienceMultiSelect"
          class="multiselect mtop"
          v-model="multiselect.audiences"
          :placeholder="t('components.catalog_publication.filters.pick_several_options')"
          label="name"
          track-by="id"
          :options="audiences"
          :multiple="true"
          :taggable="true"
          @select="((e) => selectAllOrSeveral(e, 'audiences', multiselect))"
          @remove="((e) => preventAllRemoval(e, multiselect, 'audiences', MULTISELECT_ARRAYS))"
          :hideSelected="true">
        </VueMultiselect>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-title_subtitle"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.title_subtitle')}}</label>
        <input type="text" class="form-control" v-model="selected.title">
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-author"
      >
        <DynamicSearch
          :title="'author'"
          :id="'authorSelector'"
          :selectedId="'author_id'"
          :fetcher="'fetchCatalogPublicationAuthors'"
          :loadedOptionsProperty="'authors'"
          :mapperAndLang="{ mapper: 'mapAuthors', lang: null }"
          :lang="props.lang"
          @optionClick="optionClick"
          @clearField="clearField"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-imprint"
      >
        <DynamicSearch
          :title="'imprint'"
          :id="'imprintSelector'"
          :selectedId="'imprint_id'"
          :fetcher="'fetchCatalogPublicationImprints'"
          :loadedOptionsProperty="'imprints'"
          :mapperAndLang="{ mapper: 'mapImprints', lang: null }"
          :lang="props.lang"
          @optionClick="optionClick"
          @clearField="clearField"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-collection"
      >
        <DynamicSearch
          :title="'collection'"
          :id="'collectionsSelector'"
          :selectedId="'collection_id'"
          :fetcher="'fetchCatalogPublicationCollections'"
          :loadedOptionsProperty="'collections'"
          :mapperAndLang="null"
          :lang="props.lang"
          @optionClick="optionClick"
          @clearField="clearField"
        />
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-catalog_type"
      >
        <label class="form-label">
          {{t('components.catalog_publication.filters.catalog_type')}}
        </label>
        <VueMultiselect
          id="catalogTypeMultiSelect"
          class="multiselect mtop"
          :placeholder="t('components.catalog_publication.filters.pick_several_options')"
          label="name"
          track-by="value"
          v-model="multiselect.catalog_types"
          :options="catalog_types"
          :multiple="true"
          :taggable="true"
          @select="((e) => selectAllOrSeveral(e, 'catalog_types', multiselect))"
          @remove="((e) => preventAllRemoval(e, multiselect, 'catalog_types', MULTISELECT_ARRAYS))"
          :hideSelected="true">
        </VueMultiselect>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-isbn"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.isbn')}}</label>
        <input type="text" class="form-control" v-model="selected.isbn">
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-from_quebec"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.from_quebec')}}</label>
        <select class="form-select" v-model="selected.from_quebec" @input="inputChange">
          <option v-for="(option) in allTrueFalse" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div
        class="col col-md-2 my-2"
        id="field-cover"
      >
        <label class="form-label">{{t('components.catalog_publication.filters.cover')}}</label>
        <select class="form-select" v-model="selected.cover" @input="inputChange">
          <option v-for="(option) in allTrueFalse" :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <div class="col my-3 float-start">
          <button type="button" class="btn btn-secondary me-2" @click.self="resetFilters()">
            {{t('components.catalog_publication.filters.reset_filters')}}
          </button>
          <SorterMenu
            id="sorterMenuFilters"
            @showChangePositionAction="showChangePositionAction"
            @togglePositionMenu="togglePositionMenu"
            :activeFilters="activeFilters"
            :inactiveFilters="inactiveFilters"
            :title="t('components.catalog_publication.filters.sorter_change_filters_title')"
            :getCanBeHidden="getFiltersCanBeHidden"
            :type="'filters'"
            :showBorder="true"
            class="my-3"
          />
        </div>
      </div>
      <div class="col">
        <div class="col my-3 float-end">
          <button type="button" class="btn btn-light me-2" @click.self="settingsRedirect(e)">
            {{t('components.catalog_publication.filters.save_settings')}}
          </button>
          <button type="button" class="btn btn-primary" @click.self="applyFilters(e)">
            {{t('components.catalog_publication.filters.search')}}
          </button>
        </div>
      </div>
    </div>

    <div>
      <Sorter
        v-if="showChangePositionElement"
        @showChangePositionAction="showChangePositionAction"
        @closeSorter="showChangePositionElement = false"
        :activeFilters="activeFilters"
        :inactiveFilters="inactiveFilters"
        :type="'filters'"
        :showBorder="true"
        id="sorterFilters"
        class="my-3"
      />
    </div>

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
