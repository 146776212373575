<script setup>
  import { defineProps, defineEmits, toRaw, ref, defineExpose } from 'vue';
  import { useI18n } from 'vue-i18n';
  import {
    dynamicSearchStandalone,
    categorySearchTrigger,
    debounce
  } from '../helpers/CatalogPublicationFiltersHelper';
  import { useCatalogPublicationCategoriesStore } from '../../stores/CatalogPublicationCategoriesStore';
  import { useCatalogPublicationLanguagesStore } from '../../stores/CatalogPublicationLanguagesStore';
  import { useCatalogPublicationAuthorsStore } from '../../stores/CatalogPublicationAuthorsStore';
  import { useCatalogPublicationImprintsStore } from '../../stores/CatalogPublicationImprintsStore';
  import { useCatalogPublicationCollectionsStore } from '../../stores/CatalogPublicationCollectionsStore.js';
  import { useCatalogPublicationLibrariesStore } from '../../stores/CatalogPublicationLibrariesStore';
  import { useCatalogPublicationLibraryStore } from '../../stores/CatalogPublicationLibraryStore';
  
  const { t } = useI18n();

  const languagesStore = useCatalogPublicationLanguagesStore();
  const categoriesStore = useCatalogPublicationCategoriesStore();
  const authorsStore = useCatalogPublicationAuthorsStore();
  const imprintsStore = useCatalogPublicationImprintsStore();
  const collectionsStore = useCatalogPublicationCollectionsStore();
  const librariesStore = useCatalogPublicationLibrariesStore();
  const libraryStore = useCatalogPublicationLibraryStore();
  const props = defineProps({
    title: { type: String, default: '' },
    id: { type: String, default: '' },
    selectedId: { type: String, default: '' },
    fetcher: { type: String, default: '' },
    loadedOptionsProperty: { type: String, default: '' },
    mapperAndLang: { type: Object, default: {} },
    lang: { type: String, default: '' }
  });

  const emit = defineEmits([
    'optionClick',
    'clearField'
  ]);

  const loadedOptions = ref([]);

  const setSelected = async (selector, value, name) => {
    if (selector == 'categorySelector') {
      const categories =  await categoriesStore.fetchCategories(value);
      const categoryAttr = categories.data[0].attributes;
      const input = window.document.getElementById(selector);
      if (!input) return;
      input.value = categoryAttr[`name_${props.lang}`];
    }
    if (selector == 'librarySelector') {
      const input = window.document.getElementById(selector);
      if (!input) return;
      input.value = name;
    }
  }
  defineExpose({ setSelected })

  const search = async (event) => {
    if (props.title === 'category') {
      categorySearch(event);
      return;
    } else {
      dynamicSearchStandalone(
        event, 
        loadedOptions, 
        getFetcher(),
        toRaw,
        props.fetcher, 
        props.mapperAndLang, 
        t,
        props.title
      );
    }
  }

  const getFetcher = () => {
    if (props.title === 'language') return languagesStore;
    if (props.title === 'author') return authorsStore;
    if (props.title === 'imprint') return imprintsStore;
    if (props.title === 'collection') return collectionsStore;
    if (props.title === 'libraries') return librariesStore;
  }

  const optionClick = (event) => {
    emit('optionClick', event);
  }

  const clearField = (field, inputId, isDate) => {
    emit('clearField', field, inputId, isDate);
  }

  const categorySearch = async (event) => {
    if (event.inputType === 'insertFromPaste') {
      categorySearchTrigger(event.target.value, props.lang, toRaw, loadedOptions, categoriesStore, props.title);
    } else if (event.inputType === 'insertText' || event.inputType === 'deleteContentBackward') {
      debounce(event.target.value).then(async (valid) => {
        if (!valid) return;
        categorySearchTrigger(event.target.value, props.lang, toRaw, loadedOptions, categoriesStore, props.title);
      });
    }
  }

  // Some properties have different names in the UI and our
  // system, this function returns the correct property name
  const getCorrectProperty = () => {
    switch (props.selectedId) {
      case 'category':
        return 'cat';
      case 'author':
        return 'author_id';
      case 'imprint':
        return 'imprint_id';
      case 'collection':
        return 'collection_id';
      case 'library_id':
        return 'library_id';
      case 'lang':
        return 'lang';
      default:
        return props.title;
    }
  }

</script>

<template>
  <label class="form-label">
    {{t(`components.catalog_publication.filters.${props.title}`)}} &nbsp;
    <span :id="`${props.title}_loader`" class="not_found"></span>
  </label>
  <div class="input-group">
    <input
      class="form-control"
      :list="`${props.title}_list`"
      placeholder="&#128270;"
      v-on:input="search"
      :id="props.id">
    <datalist :id="`${props.title}_list`">

      <option
        v-if="props.title === 'libraries'"
        v-for="library in loadedOptions" 
        :value="library.attributes.name"
        :data-selected="library.id"
        @click="optionClick">
        {{ library.attributes.name }}
      </option>

      <option 
        v-if="props.title === 'imprint'"
        v-for="item in loadedOptions" 
        :value="item.name"
        :data-selected="item.id"
        @click="optionClick"
        >
        {{ item.name }}
      </option>

      <option
        v-if="props.title === 'language'"
        v-for="language in loadedOptions" 
        :value="`${language.name} (${language.nameOriginal})`"
        :data-selected="language.alpha2"
        @click="optionClick"
      >{{ `${language.name} (${language.nameOriginal})` }}</option>

      <option 
        v-if="props.title === 'category'"
        v-for="category in loadedOptions.categories" 
        :value="category.name"
        :data-selected="category.code"
        @click="optionClick"
        >
        {{ category.map }}
      </option>

      <option
        v-if="props.title === 'author'"
        v-for="author in loadedOptions" 
        :value="author.nameOriginal"
        :data-selected="author.id"
        @click="optionClick"
        >
        {{ author.nameOriginal }}
      </option>

      <option
        v-if="props.title === 'collection'"
        v-for="collection in loadedOptions" 
        :value="collection.attributes.original_name"
        :data-selected="collection.id"
        @click="optionClick"
        >
        {{ collection.attributes.original_name }}
      </option>
      
    </datalist>
    <span
      class="input-group-text"
      @click="clearField(
        getCorrectProperty(), 
        props.id, 
        false
      )"
      >x
    </span>
  </div>
</template>
